import { assign } from 'xstate';

import { Animator } from 'features/common/services/animator.service';
import { getTranslationData } from 'features/common/services/event.service';
import { EventProcessor } from 'features/futsal/services/processor.service';

const assignStore = () =>
    assign({
        store: (ctx, evt) => {
            const { extra, teamsreverse } = evt.data;
            let duration;
            const homeTeam = teamsreverse ? 2 : 1;
            const awayTeam = teamsreverse ? 1 : 2;
            if (extra) {
                duration = extra.duration || ctx.store.translationData.statistics.matchDuration;
            }

            return {
                ...ctx.store,
                homeTeam,
                awayTeam,
                translationData: {
                    ...ctx.store.translationData,
                    info: evt.data,
                    statistics: {
                        ...ctx.store.translationData.statistics,
                        matchDuration: duration,
                    },
                },
            };
        },
    });

const assignEventProcessor = () =>
    assign({
        eventProcessor: (ctx) => {
            const animator = new Animator(ctx.store);
            return new EventProcessor(ctx.store, animator);
        },
    });

export const idle = {
    invoke: {
        id: 'idle-invoke',
        src: (ctx) => getTranslationData(ctx.translationId),
        onDone: {
            target: 'initialEvents',
            actions: [assignStore(), assignEventProcessor()],
        },
        onError: {
            target: 'failure',
            actions: assign({ error: (_, event) => event.data }),
        },
    },
};
