import { assign } from 'xstate';

import { hasLength } from 'lib';
import { getlastEventId, getTranslationEvents } from 'features/common/services/event.service';

export const initialEvents = {
    invoke: {
        id: 'initialEvents-invoke',
        src: (context) => getTranslationEvents(context.translationId, context.lastEventId),
        onDone: [
            { target: 'initialEventsDelay', cond: 'hasGotNoInitialEvents' },
            {
                target: 'delay',
                actions: assign({
                    store: (context, event) => {
                        context.eventProcessor.processInitialEvents(event.data);
                        const { log, statistics } = context.eventProcessor.store.translationData;
                        return {
                            ...context.store,
                            translationData: {
                                ...context.store.translationData,
                                log,
                                statistics: { ...statistics },
                            },
                        };
                    },
                    lastEventId: (context, event) => {
                        if (hasLength(event.data)) {
                            return getlastEventId(event.data);
                        }
                        return context.lastEventId;
                    },
                    // timer: timerAction,
                }),
            },
        ],
        onError: {
            target: 'failure',
            actions: assign({ error: (_, event) => event.data }),
        },
    },
};
