/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';
import { getTimeText } from './common';
// import { getTimeText } from './common';

export const YellowCardEvent = new Event({
    type: ModelMap.YellowCard,
    animationName: AnimationMap.YellowCard,
    calculate(store, eventData) {
        const { statistics } = store.translationData;

        statistics.yellowCards[eventData.team].push({
            time: eventData.time,
            period: store.currentPeriod,
            id: eventData.id,
        });
    },
    pushToLog(log, eventData, store) {
        const { statistics } = store.translationData;
        const { currentPeriod } = statistics;

        log.push({
            component: 'DefaultLogEvent',
            team: eventData.team,
            text: i18n.t('log.yellowCard'),
            svg: 'log/card',
            time: eventData.time,
            timeText: getTimeText(eventData.time, statistics),
            period: currentPeriod,
            type: 'yellowCard',
            id: eventData.id,
        });
    },
});
