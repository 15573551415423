import { assign, Machine } from 'xstate';

import { hasLength } from 'lib';
import { Animator } from 'features/common/services/animator.service';
import { getlastEventId, getTranslationData, getTranslationEvents } from 'features/common/services/event.service';
import { EventProcessor } from 'features/tableTennis/services/processor.service';
import { stateContext } from './state.context';

const storeAction = (context, event) => {
    context.eventProcessor.checkForNewEvents(event.data);
    const { log, statistics } = context.eventProcessor.store.translationData;
    return {
        ...context.eventProcessor.store,
        translationData: {
            ...context.store.translationData,
            log,
            statistics: { ...statistics },
        },
    };
};
const lastEventIdAction = (context, event) => {
    if (hasLength(event.data)) {
        return getlastEventId(event.data);
    }
    return context.lastEventId;
};

export function rootMachine({ translationId }) {
    return Machine(
        {
            id: 'root',
            initial: 'idle',
            context: {
                ...stateContext,
                translationId,
            },
            states: {
                idle: {
                    invoke: {
                        id: 'idle-invoke',
                        src: (context) => getTranslationData(context.translationId),
                        onDone: {
                            target: 'initialEvents',
                            actions: [
                                assign({
                                    store: (context, event) => {
                                        const { teamsreverse } = event.data;
                                        const homeTeam = teamsreverse ? 2 : 1;
                                        const awayTeam = teamsreverse ? 1 : 2;
                                        return {
                                            ...context.store,
                                            homeTeam,
                                            awayTeam,
                                            translationData: {
                                                ...context.store.translationData,
                                                info: event.data,
                                            },
                                        };
                                    },
                                }),
                                assign({
                                    eventProcessor: (context) => {
                                        const animator = new Animator(context.store);
                                        return new EventProcessor(context.store, animator);
                                    },
                                }),
                            ],
                        },
                        onError: {
                            target: 'failure',
                            actions: assign({ error: (_, event) => event.data }),
                        },
                    },
                },
                failure: {
                    type: 'final',
                },
                disabled: {
                    invoke: {
                        id: 'disabled-invoke',
                        src: (context) => getTranslationEvents(context.translationId, context.lastEventId),
                        onDone: {
                            target: 'delay',
                            actions: assign({
                                store: storeAction,
                                lastEventId: lastEventIdAction,
                            }),
                        },
                        onError: {
                            target: 'disabledDelay',
                        },
                    },
                },
                initialEvents: {
                    invoke: {
                        id: 'initialEvents-invoke',
                        src: (context) => getTranslationEvents(context.translationId, context.lastEventId),
                        onDone: [
                            { target: 'initialEventsDelay', cond: 'hasGotNoInitialEvents' },
                            {
                                target: 'delay',
                                actions: assign({
                                    store: (context, event) => {
                                        context.eventProcessor.processInitialEvents(event.data);
                                        const { log, statistics } = context.eventProcessor.store.translationData;
                                        return {
                                            ...context.store,
                                            translationData: {
                                                ...context.store.translationData,
                                                log,
                                                statistics: { ...statistics },
                                            },
                                        };
                                    },
                                    lastEventId: (context, event) => {
                                        if (hasLength(event.data)) {
                                            return getlastEventId(event.data);
                                        }
                                        return context.lastEventId;
                                    },
                                }),
                            },
                        ],
                        onError: {
                            target: 'failure',
                            actions: assign({ error: (_, event) => event.data }),
                        },
                    },
                },
                newEvents: {
                    invoke: {
                        id: 'newEvents-invoke',
                        src: (context) => getTranslationEvents(context.translationId, context.lastEventId),
                        onDone: {
                            target: 'delay',
                            actions: assign({
                                store: storeAction,
                                lastEventId: lastEventIdAction,
                            }),
                        },
                        onError: [
                            {
                                target: 'disabledDelay',
                                cond: 'isWidgetDisabled',
                                actions: assign({ error: (_, event) => event.data }),
                            },
                            {
                                target: 'delay',
                                actions: assign({ error: (_, event) => event.data }),
                            },
                        ],
                    },
                },
                delay: {
                    after: { 1000: 'newEvents' },
                },
                initialEventsDelay: {
                    after: { 1000: 'initialEvents' },
                },
                disabledDelay: {
                    after: { 3000: 'disabled' },
                },
            },
        },
        {
            guards: {
                hasGotNoInitialEvents: (_, event) => event.data.length === 0,
                isWidgetDisabled: (_, event) => event.data.message.indexOf('disabledMatchError') > -1,
            },
        },
    );
}
