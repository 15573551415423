import { assign } from 'xstate';

import { getTranslationEvents } from 'features/common/services/event.service';
import { machineUtils } from 'features/common/utils';

const assignStore = (context, event) => {
    context.eventProcessor.processInitialEvents(event.data);
    const { log, statistics } = context.eventProcessor.store.translationData;

    return {
        ...context.store,
        translationData: {
            log,
            ...context.store.translationData,
            statistics: { ...statistics },
        },
    };
};

export const initialEvents = {
    invoke: {
        id: 'initialEvents-invoke',
        src: (ctx) => getTranslationEvents(ctx.translationId, ctx.lastEventId),
        onDone: [
            { target: 'initialEventsDelay', cond: 'hasGotNoInitialEvents' },
            {
                target: 'delay',
                actions: assign({
                    store: assignStore,
                    lastEventId: machineUtils.lastEventIdAction,
                }),
            },
        ],
        onError: {
            target: 'failure',
            actions: assign({ error: (_, event) => event.data }),
        },
    },
};
