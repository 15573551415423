import React from 'react';
import PropTypes from 'prop-types';

import { useErrorConfig } from 'hooks';
import { DisabledError, MatchEndError, NetworkError, ServerError } from '../../atoms';

export function ErrorPageHandler({ sport }) {
    const { error, translate, isDisabled, isFailed, handleRefresh } = useErrorConfig();

    function renderError(err) {
        if (err.message.indexOf('disabledMatchError') > -1) {
            return <DisabledError translate={translate} sport={sport} />;
        }
        if (err.message.indexOf('matchEnd') > -1) {
            return <MatchEndError translate={translate} sport={sport} />;
        }
        if (err.message.indexOf('Network Error') > -1) {
            return <NetworkError translate={translate} onRefresh={handleRefresh} sport={sport} />;
        }
        if (err.message.indexOf('serverError') > -1) {
            return <ServerError translate={translate} onRefresh={handleRefresh} sport={sport} />;
        }
        return <ServerError translate={translate} onRefresh={handleRefresh} sport={sport} />;
    }
    return <>{isFailed || isDisabled ? renderError(error) : null}</>;
}

ErrorPageHandler.propTypes = {
    errors: PropTypes.shape({
        DisabledError: PropTypes.func,
        MatchEndError: PropTypes.func,
        NetworkError: PropTypes.func,
        ServerError: PropTypes.func,
    }).isRequired,
};
