/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';

export const PenaltyShotoutEvent = new Event({
    type: ModelMap.PenaltyShootout,
    animationName: AnimationMap.PenaltyShootout,
    calculate(store) {
        store.translationData.statistics.penaltyShootoutStarted = true;
    },
    pushToLog(log, eventData, store) {
        const { currentPeriod } = store.translationData.statistics;
        log.push({
            component: 'GeneralLogEvent',
            text: i18n.t('log.penaltyShootout'),
            period: currentPeriod,
            type: 'score',
            id: eventData.id,
            translucent: true,
        });
    },
});
