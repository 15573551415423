import { Machine } from 'xstate';

import { state } from './context';
import { disabled, idle, initialEvents, newEvents } from './states';

export const rootMachine = ({ translationId }) =>
    Machine(
        {
            id: 'root',
            initial: 'idle',
            context: {
                ...state,
                translationId,
            },
            states: {
                idle,
                disabled,
                initialEvents,
                newEvents,
                failure: {
                    type: 'final',
                },
                delay: {
                    after: { 1000: 'newEvents' },
                },
                initialEventsDelay: {
                    after: { 1000: 'initialEvents' },
                },
                disabledDelay: {
                    after: { 3000: 'disabled' },
                },
            },
        },
        {
            guards: {
                hasGotNoInitialEvents: (_, event) => event.data.length === 0,
                isWidgetDisabled: (_, event) => event.data.message.indexOf('disabledMatchError') > -1,
            },
        },
    );
