/* eslint-disable no-param-reassign */
import i18n from 'i18n';

import { Event } from 'features/common/models/event.model';
import { AnimationMap, ModelMap } from 'features/handball/constants';
import { getTimeText } from './common';

const l = (t) => {
    const { penaltyShootout, goals } = t;
    return [1, 2].some((team) => {
        const secondTeam = team === 1 ? 2 : 1;
        const n = goals[team].filter((goal) => goal.isPenalty).length;
        const s = goals[secondTeam].filter((goal) => goal.isPenalty).length;
        const r = penaltyShootout[team].filter((shootout) => !shootout.status).length;
        const o = penaltyShootout[secondTeam].filter((shootout) => !shootout.status).length;
        // eslint-disable-next-line no-mixed-operators
        return n + r < s || (o === 0 && r === 0);
    });
};

const c = (statistics) => {
    const { penaltyShootout, goals } = statistics;
    const i = (team) => penaltyShootout[team].filter((t) => !t.status).length === 0;
    const team1PenaltyGoals = goals[1].filter((t) => t.isPenalty).length;
    const team2penaltyGoals = goals[2].filter((t) => t.isPenalty).length;

    // eslint-disable-next-line max-len,no-unused-expressions
    i(1) &&
        i(2) &&
        team1PenaltyGoals - team2penaltyGoals === 0 &&
        (penaltyShootout[1].push({}), penaltyShootout[2].push({}));
};

export const PenaltyGoalEvent = new Event({
    type: ModelMap.PenaltyGoal,
    animationName: AnimationMap.PenaltyGoal,
    calculate(store, eventData) {
        const { statistics } = store.translationData;
        const { penaltyShootout: a } = statistics;
        a[eventData.team].find((t) => !t.status).status = 'scored';
        store.goals[eventData.team].push({
            time: eventData.time,
            period: store.currentPeriod,
            id: eventData.id,
            isPenalty: !0,
        });
        c(statistics);
    },
    pushToLog(log, eventData, store) {
        const { homeTeam, awayTeam } = store;
        const { statistics: i } = store.translationData;
        const { goals: n, currentPeriod: s } = i;
        const text = `${i18n.t('log.penaltyGoal')}, ${n[homeTeam].length}:${n[awayTeam].length}`;
        log.push({
            component: 'DefaultLogEvent',
            team: eventData.team,
            text,
            svg: 'log/goal',
            time: eventData.time,
            timeText: getTimeText(eventData.time, i),
            period: s,
            type: 'score',
            id: eventData.id,
        });
    },
    pushAdditionalEvents(event, store) {
        return l(store.translationData.statistics)
            ? []
            : [
                  {
                      ...event,
                      type: ModelMap.PenaltyThrow,
                      team: event.team === 1 ? 2 : 1,
                  },
              ];
    },
});
